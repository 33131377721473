var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"3","md":"5","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"},[_vm._v(" "+_vm._s(_vm.$get(_vm.getContractDetails,'contractor.name',''))+" ")]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('contracts:signDate')}`)+":")]),_vm._v(" "+_vm._s(_vm.getContractDetails.signDate ? _vm.$moment( _vm.getContractDetails.signDate ).format(`DD/MM/YYYY`) : _vm.$t('global:empty')))]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('contracts:expirationDate')}`)+":")]),_vm._v(" "+_vm._s(_vm.getContractDetails.expirationDate ? _vm.$moment( _vm.getContractDetails.expirationDate ).format(`DD/MM/YYYY`) : _vm.$t('global:empty')))])])],1)],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticStyle:{"width":"60%"},attrs:{"readonly":"","value":_vm.$get(_vm.getContractDetails, 'number', ''),"label":_vm.$t('contracts:number')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.$get(_vm.getContractDetails, 'title', ''),"label":_vm.$t('contracts:title')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.$get(_vm.getContractDetails, 'contractor.name', ''),"label":_vm.$t('contracts:contractor')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.findRepresentative(_vm.getContractDetails),"label":_vm.$t('contracts:representative')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticStyle:{"width":"60%"},attrs:{"readonly":"","value":_vm.getContractDetails.signDate
                                ? _vm.$moment(
                                      _vm.getContractDetails.signDate
                                  ).format('DD-MM-YYYY')
                                : _vm.$t('global:empty'),"label":_vm.$t('contracts:signDate')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticStyle:{"width":"60%"},attrs:{"readonly":"","value":_vm.getContractDetails.expirationDate
                                ? _vm.$moment(
                                      _vm.getContractDetails.expirationDate
                                  ).format('DD-MM-YYYY')
                                : _vm.$t('global:empty'),"label":_vm.$t('contracts:expirationDate')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"readonly":"","append-icon":"","items":[_vm.getContractDetails.contractType],"dense":"","label":_vm.$t('contracts:contractType'),"return-object":"","item-value":"field"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(data.item.backgroundColor)?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item.backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                            ? data.item.textColor
                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(data.item.backgroundColor)?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item.backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                            ? data.item.textColor
                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(_vm.getContractDetails.contractType),callback:function ($$v) {_vm.$set(_vm.getContractDetails, "contractType", $$v)},expression:"getContractDetails.contractType"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"readonly":"","value":_vm.$get(_vm.getContractDetails, 'comment', ''),"label":_vm.$t('contracts:comment'),"no-resize":"","rows":"1","auto-grow":""}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }