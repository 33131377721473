<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            {{ $get(getContractDetails,'contractor.name','') }}
                        </p>
                        <span
                            ><b>{{ `${$t('contracts:signDate')}` }}:</b>
                            {{
                                getContractDetails.signDate
                                    ? $moment(
                                          getContractDetails.signDate
                                      ).format(`DD/MM/YYYY`)
                                    : $t('global:empty')
                            }}</span
                        >
                        <span
                            ><b>{{ `${$t('contracts:expirationDate')}` }}:</b>
                            {{
                                getContractDetails.expirationDate
                                    ? $moment(
                                          getContractDetails.expirationDate
                                      ).format(`DD/MM/YYYY`)
                                    : $t('global:empty')
                            }}</span
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="6"
                        ><v-text-field
                            style="width: 60%;"
                            readonly
                            :value="$get(getContractDetails, 'number', '')"
                            :label="$t('contracts:number')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="$get(getContractDetails, 'title', '')"
                            :label="$t('contracts:title')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="
                                $get(getContractDetails, 'contractor.name', '')
                            "
                            :label="$t('contracts:contractor')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="findRepresentative(getContractDetails)"
                            :label="$t('contracts:representative')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6"
                        ><v-text-field
                            style="width: 60%;"
                            readonly
                            :value="
                                getContractDetails.signDate
                                    ? $moment(
                                          getContractDetails.signDate
                                      ).format('DD-MM-YYYY')
                                    : $t('global:empty')
                            "
                            :label="$t('contracts:signDate')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6"
                        ><v-text-field
                            style="width: 60%;"
                            readonly
                            :value="
                                getContractDetails.expirationDate
                                    ? $moment(
                                          getContractDetails.expirationDate
                                      ).format('DD-MM-YYYY')
                                    : $t('global:empty')
                            "
                            :label="$t('contracts:expirationDate')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    
                    <v-col cols="8"
                        >
                        <v-select
                            readonly
                            append-icon=""
                            v-model="getContractDetails.contractType"
                            :items ="[getContractDetails.contractType]"
                            dense
                            :label="$t('contracts:contractType')"
                            return-object
                            item-value="field"
                            ><template v-slot:item="data">
                                <span v-if="data.item.backgroundColor">
                                    <v-chip
                                        class="mx-2"
                                        :color="data.item.backgroundColor"
                                        small
                                        label
                                        :text-color="
                                            data.item.textColor
                                                ? data.item.textColor
                                                : '#4d4d4d'
                                        "
                                    >
                                        {{ data.item.field }}
                                    </v-chip>
                                </span>
                                <span class="truncate-list" v-else>
                                    {{ data.item.field }}
                                </span>
                            </template>
                            <template v-slot:selection="data">
                                <span v-if="data.item.backgroundColor">
                                    <v-chip
                                        class="mx-2"
                                        :color="data.item.backgroundColor"
                                        small
                                        label
                                        :text-color="
                                            data.item.textColor
                                                ? data.item.textColor
                                                : '#4d4d4d'
                                        "
                                    >
                                        {{ data.item.field }}
                                    </v-chip>
                                </span>
                                <span class="truncate-list" v-else>
                                    {{ data.item.field }}
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-textarea
                            readonly
                            :value="$get(getContractDetails, 'comment', '')"
                            :label="$t('contracts:comment')"
                            no-resize
                            rows="1"
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters(['getContractDetails']),
    },
    methods: {
        findRepresentative(item) {
            const id = item.representative
            if (id) {
                const representative = item.contractor?.representatives?.find(
                    e => e._id === id
                )

                return `${this.$get(
                    representative,
                    'name',
                    'Brak'
                )} ${this.$get(representative, 'lastname', '')} ${
                    representative && representative.email ? '•' : ''
                } ${this.$get(representative, `email`, '')} ${
                    representative && representative.phoneNumber ? '•' : ''
                } ${this.$get(representative, 'phoneNumber', '')}`
            }
            return ''
        },
    }
}
</script>
