var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.getContractErrors.step),callback:function ($$v) {_vm.$set(_vm.getContractErrors, "step", $$v)},expression:"getContractErrors.step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getContractErrors.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('contracts:basicInfo')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pb-0 pt-4",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('contracts:basicInfo')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"error-messages":_vm.getContractErrors.stepOne.fields
                                                .number,"label":`${_vm.$t(
                                            'contracts:number'
                                        )}*`},model:{value:(_vm.getContractModal.number),callback:function ($$v) {_vm.$set(_vm.getContractModal, "number", $$v)},expression:"getContractModal.number"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                on,
                                                attrs,
                                            }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":`${_vm.$t(
                                                    'contracts:signDate'
                                                )}*`,"prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.getContractErrors
                                                        .stepOne.fields
                                                        .signDate},on:{"click:clear":function($event){_vm.getContractModal.signDate = null}},model:{value:(
                                                    _vm.getContractModal.signDate
                                                ),callback:function ($$v) {_vm.$set(_vm.getContractModal, "signDate", $$v)},expression:"\n                                                    getContractModal.signDate\n                                                "}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{on:{"input":function($event){_vm.startDate = false}},model:{value:(
                                                _vm.getContractModal.signDate
                                            ),callback:function ($$v) {_vm.$set(_vm.getContractModal, "signDate", $$v)},expression:"\n                                                getContractModal.signDate\n                                            "}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                on,
                                                attrs,
                                            }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":`${_vm.$t(
                                                    'contracts:expirationDate'
                                                )}`,"prepend-icon":"mdi-calendar","readonly":""},on:{"click:clear":function($event){_vm.getContractModal.expirationDate = null}},model:{value:(
                                                    _vm.getContractModal.expirationDate
                                                ),callback:function ($$v) {_vm.$set(_vm.getContractModal, "expirationDate", $$v)},expression:"\n                                                    getContractModal.expirationDate\n                                                "}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{on:{"input":function($event){_vm.startDate = false}},model:{value:(
                                                _vm.getContractModal.expirationDate
                                            ),callback:function ($$v) {_vm.$set(_vm.getContractModal, "expirationDate", $$v)},expression:"\n                                                getContractModal.expirationDate\n                                            "}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"error-messages":_vm.getContractErrors.stepOne.fields
                                                .title,"label":`${_vm.$t('contracts:title')}*`},model:{value:(_vm.getContractModal.title),callback:function ($$v) {_vm.$set(_vm.getContractModal, "title", $$v)},expression:"getContractModal.title"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticStyle:{"width":"60%","float":"right"},attrs:{"items":_vm.getRegistryDictionaries.contractType
                                                ? _vm.getRegistryDictionaries.contractType
                                                : [],"label":`${_vm.$t(
                                            'contracts:contractType'
                                        )}*`,"return-object":"","item-value":"field","error-messages":_vm.getContractErrors.stepOne.fields
                                                .contractType},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}},{key:"selection",fn:function(data){return [(
                                                    data.item
                                                        .backgroundColor
                                                )?_c('span',[_c('v-chip',{staticClass:"mx-2",attrs:{"color":data.item
                                                            .backgroundColor,"small":"","label":"","text-color":data.item.textColor
                                                            ? data.item
                                                                  .textColor
                                                            : '#4d4d4d'}},[_vm._v(" "+_vm._s(data.item.field)+" ")])],1):_c('span',{staticClass:"truncate-list"},[_vm._v(" "+_vm._s(data.item.field)+" ")])]}}]),model:{value:(
                                            _vm.getContractModal.contractType
                                        ),callback:function ($$v) {_vm.$set(_vm.getContractModal, "contractType", $$v)},expression:"\n                                            getContractModal.contractType\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"label":`${_vm.$t(
                                            'contracts:contractor'
                                        )}*`,"hint":"Zacznij pisać...","hide-no-data":_vm.hideContacts,"items":_vm.getContactsInput.items,"item-text":"name","item-value":"_id","return-object":"","error-messages":_vm.getContractErrors.stepOne.fields
                                                .contractor},on:{"keyup":function($event){return _vm.setContacts($event.target.value)},"input":function($event){return _vm.assignID()}},model:{value:(
                                            _vm.getContractModal.contractor
                                        ),callback:function ($$v) {_vm.$set(_vm.getContractModal, "contractor", $$v)},expression:"\n                                            getContractModal.contractor\n                                        "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-combobox',{attrs:{"label":`${_vm.$t(
                                            'contracts:representative'
                                        )}`,"item-text":_vm.text,"return-object":"","append-outer-icon":"mdi-account-plus","item-value":"_id","items":_vm.getRepresentativeInput.items},on:{"keyup":function($event){return _vm.setRepresentatives(
                                                $event.target.value
                                            )}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.lastname)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.lastname)+" ")]}}]),model:{value:(
                                            _vm.getContractModal.representative
                                        ),callback:function ($$v) {_vm.$set(_vm.getContractModal, "representative", $$v)},expression:"\n                                            getContractModal.representative\n                                        "}},[_c('template',{slot:"append-outer"},[_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":false,"max-width":"300","min-width":"300","origin":"center"},on:{"input":v =>
                                                        v ||
                                                        _vm.clearMiniModal()},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on: menu,
                                                    }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                                on: tooltip,
                                                            }){return [_c('v-btn',{attrs:{"small":"","icon":"","disabled":_vm.getContactsInput
                                                                        .items
                                                                        .length >
                                                                    0
                                                                        ? false
                                                                        : true}},[_c('v-icon',_vm._g({on:{"click":function($event){_vm.showMenu = true}}},{
                                                                        ...menu,
                                                                        ...tooltip,
                                                                    }),[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t( 'contracts:addRepresentative' )))])])]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(" "+_vm._s(`${_vm.$t( 'contracts:additionRepresentative' )}`)+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                        'contracts:representativeName'
                                                                    )}`},model:{value:(
                                                                        _vm.getRepresentativeModal.name
                                                                    ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "name", $$v)},expression:"\n                                                                        getRepresentativeModal.name\n                                                                    "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                        'contracts:representativeLastname'
                                                                    )}`},model:{value:(
                                                                        _vm.getRepresentativeModal.lastname
                                                                    ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "lastname", $$v)},expression:"\n                                                                        getRepresentativeModal.lastname\n                                                                    "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                        'contracts:representativeEmail'
                                                                    )}`},model:{value:(
                                                                        _vm.getRepresentativeModal.email
                                                                    ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "email", $$v)},expression:"\n                                                                        getRepresentativeModal.email\n                                                                    "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":`${_vm.$t(
                                                                        'contracts:representativePhoneNumber'
                                                                    )}`},model:{value:(
                                                                        _vm.getRepresentativeModal.phoneNumber
                                                                    ),callback:function ($$v) {_vm.$set(_vm.getRepresentativeModal, "phoneNumber", $$v)},expression:"\n                                                                        getRepresentativeModal.phoneNumber\n                                                                    "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0 my-0 d-flex justify-end",attrs:{"md":"12"}},[_c('v-btn',{staticClass:"buttons buttons--cancel mr-2",on:{"click":function($event){return _vm.clearMiniModal()}}},[_vm._v(_vm._s(_vm.$t( 'cancel' )))]),_c('v-btn',{staticClass:"buttons buttons--add",attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.additionRepresentative()}}},[_vm._v(_vm._s(_vm.$t( 'add' )))])],1)],1)],1)],1)],1)],1)],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":`${_vm.$t(
                                            'contracts:comment'
                                        )}`,"rows":"1","auto-grow":"","no-resize":""},model:{value:(_vm.getContractModal.comment),callback:function ($$v) {_vm.$set(_vm.getContractModal, "comment", $$v)},expression:"getContractModal.comment"}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }